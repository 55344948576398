<script>
/* eslint-disable */
import DefaultMixin from '../../mixin'

export default {
  name: 'LeilaoLive',
  inject: ['page'],
  mixins: [DefaultMixin],
  components: {},
  data() {
    return {
      leilao: this.page.leilao
    }
  },
  computed: {
    listType () {
      return this.$route.name
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    changeListType(v) {
      this.$router.push({name: v})
    }
  },
  meta: {
    title: 'Live do Leilão',
    name: 'Live'
  }
}
</script>

<template>
  <div class="page-leilao-container leilao-live">
    <div class="h-full">
      <div class="page-tab">
        <div class="erp-list list-tasks no-pad">
          <div class="erp-list-top-btns items-end">
            <div class="actives-btns col-grow-1" style="margin-left: 60px">
              <u-btn @click="changeListType('leiloes.realizacao.live.edit')" class="active-table-btn" label="Live" no-caps
                     :class="{active: listType === 'leiloes.realizacao.live.edit'}"/>
              <u-btn @click="changeListType('leiloes.realizacao.live.gracacoes')" class="active-table-btn" label="Gravações" icon="folder" icon-type="fa" no-caps
                     :class="{active: listType === 'leiloes.realizacao.live.gracacoes'}"/>
            </div>
          </div>
        </div>
        <div class="page-content">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
